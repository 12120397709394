import { v4 as uuid } from 'uuid';
import { ColorVariant } from 'bootstrap-vue-next/dist/src/types';

export type SnackObject = {
    uuid: string;
    title: string;
    subTitle: string | null;
    type: ColorVariant;
    timeout: number | null;
};

type SnackListener = (snack: SnackObject) => void;

class SnackbarService {
    listeners: SnackListener[] = [];

    addListener(listener: SnackListener): void {
        this.listeners.push(listener);
    }

    removeListener(listener: SnackListener): void {
        this.listeners = this.listeners.filter((item) => item !== listener);
    }

    showSnack(_snack: Partial<Omit<SnackObject, 'uuid'>> & Pick<SnackObject, 'title'>): void {
        const snack: SnackObject = {
            subTitle: null,
            type: 'info',
            timeout: 5000,
            ..._snack,
            uuid: uuid(),
        };
        this.listeners.forEach((listener) => listener(snack));
    }

    showInfo(subTitle: string, title = 'Informace'): void {
        this.showSnack({ title, subTitle, type: 'info' });
    }

    showWarning(subTitle: string, title = 'Varování'): void {
        this.showSnack({ title, subTitle, type: 'warning' });
    }

    showError(subTitle: string, title = 'Chyba'): void {
        this.showSnack({
            title,
            subTitle,
            type: 'danger',
            timeout: 10000,
        });
    }

    showSuccess(subTitle: string, title = 'Úspěch'): void {
        this.showSnack({ title, subTitle, type: 'success' });
    }
}

export default new SnackbarService();
