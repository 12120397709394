<template>
    <Header stars>
        <img alt="Raketa" src="@/assets/raketa_bez_loga.svg">

        <h2>Domácí kolo již začalo!</h2>
        <div class="line"/>
        <p class="heading">
            Čas na řešení úloh je do 3. 12. do 23:59:59
        </p>

        <div class="buttons">
            <!-- router-tag="button" does not work for some reason, using class instead -->
            <b-button :to="{ path: '/pravidla/' }" class="button" variant="outline-light">
                Více informací
            </b-button>

            <Register/>
        </div>
    </Header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Register from '@/components/auth/Register.vue';

export default defineComponent({
    name: 'HeaderComponent',
});
</script>

<style scoped>
h2 {
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
}

p {
    color: #af7ae4;
    font-size: 2rem;
    text-align: center;
}

img {
    margin: 1rem 0 1.5rem;
}

.line {
    background-color: #ff0052;

    width: 8rem;
    height: 2px;

    margin: 0.8rem 0;
}

.buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.buttons :deep(button),
.buttons :deep(a) {
    padding: 1rem 1.2rem;
}
</style>
