<template>
    <span v-if="required" class="required-star">*</span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'RequiredStar',
    props: {
        required: {
            type: Boolean,
            defalt: false,
        },
    },
});
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.required-star {
    color: $pink;
    font-weight: bold;
    font-size: 1.1rem;
}
</style>
