<template>
    <b-collapse :visible="!dismissed" appear>
        <b-alert :variant="bootstrapVariant" class="snack-container" :model-value="true">
            <h6 class="alert-heading">
                {{ snack.title }}
            </h6>
            <p v-if="snack.subTitle !== null" class="alert-subtitle">
                {{ snack.subTitle }}
            </p>
            <button type="button" aria-label="Close" class="close btn" @click="dismiss()">
                ×
            </button>
        </b-alert>
    </b-collapse>
</template>

<script lang="ts">
import { SnackObject } from '@/api/SnackbarService';
import type { ColorVariant } from 'bootstrap-vue-next';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'Snack',
    props: {
        snack: {
            type: Object as PropType<SnackObject>,
            required: true,
        },
    },
    data() {
        return {
            removeAnimationsDruation: 1000,
            dismissed: false,
            dimissTimeout: null as number | null,
        };
    },
    computed: {
        bootstrapVariant(): ColorVariant {
            return this.snack.type;
        },
    },
    mounted() {
        if (this.snack.timeout !== null) {
            this.dimissTimeout = setTimeout(() => this.dismiss(), this.snack.timeout);
        }
    },
    methods: {
        dismiss() {
            if (this.dismissed) return;
            this.dismissed = true;
            if (this.dimissTimeout !== null) {
                clearTimeout(this.dimissTimeout);
                this.dimissTimeout = null;
            }
            setTimeout(() => this.$emit('dismissed'), this.removeAnimationsDruation);
        },
    },
});
</script>

<style scoped>
.snack-container {
    width: 300px;
    max-width: 100vw;
    margin-bottom: 10px;
}

.alert-heading {
    margin: 0;
    font-weight: bold;
}

.alert-subtitle {
    margin-top: .25rem;
    margin-bottom: 0;
    word-wrap: break-word;
}

button.close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}
</style>
