<template>
    <div class="root" role="group">
        <b-form-checkbox :id="id" v-model="value" :required="required" @change="updateValidity">
            <slot />
            <RequiredStar :required="required" />

            <b-form-invalid-feedback>
                {{ validity }}
            </b-form-invalid-feedback>
        </b-form-checkbox>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watchEffect, onMounted } from 'vue';
import RequiredStar from '@/components/forms/RequiredStar.vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    required: { type: Boolean },
    customValidity: { type: String, default: "" }
});

const emit = defineEmits(['update:modelValue']);

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});

const id = crypto.randomUUID();
const validity = ref("");

function updateValidity() {
    let element = document.getElementById(id) as HTMLInputElement | null;
    validity.value = element?.validationMessage ?? "";
}

onMounted(() => {
    watchEffect(() => {
        let element = document.getElementById(id) as HTMLInputElement | null;
        element?.setCustomValidity(props.customValidity);

        updateValidity();
    });
});
</script>

<style scoped>
label {
    font-weight: bold;
}

.form-check-input {
    margin-top: 0;
}

.root {
    margin: 8px;
}

/* fix for displaying the invalid feedback */
.was-validated :invalid~label .invalid-feedback,
.is-invalid~label .invalid-feedback {
    display: block;
}
</style>
