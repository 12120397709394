<template>
    <a :href="discordLink">
        {{ linkText }}
    </a>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
    linkText: string,
}>(), {
    linkText: 'Discord',
});

// TODO: we might want to move this to some configuration or an .env secret
const discordLink = 'https://discord.gg/naZ3cE8fUM';
</script>
