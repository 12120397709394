/**
 * Implements a Linear congruential generator, with Borland C++ constants
 * See https://en.wikipedia.org/wiki/Linear_congruential_generator
 */
export class LCG {
    seed: number;

    static readonly multiplier: number = 22695477;
    static readonly increment: number = 1;
    static readonly modulus: number = 0x100000000;
    static readonly maximum: number = 0x1000000;

    constructor(seed: number) {
        this.seed = (seed ^ LCG.multiplier) % LCG.modulus;

        for (let i = 0; i < 4; i++) {
            this.nextRaw();
        }
    }

    private nextRaw(): number {
        this.seed = (Math.imul(this.seed, LCG.multiplier) + LCG.increment) % LCG.modulus;
        return this.seed >>> 8;
    }

    next(): number {
        return this.nextRaw() / LCG.maximum;
    }
}
