<template>
    <Header>
        <h2>
            <div class="top">
                {{ $props.top }}
            </div>
            <div class="line"/>
            <div class="bottom">
                {{ $props.bottom }}
            </div>
        </h2>
    </Header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Header from '@/components/common/Header.vue';

export default defineComponent({
    name: 'SmallHeader',
    props: {
        top: String,
        bottom: String,
    },
});
</script>

<style scoped>
h2 {
    display: contents;
}

.top {
    color: #af7ae4;
    font-size: 2rem;
    text-align: center;
}

.bottom {
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
}

.line {
    background-color: #ff0052;

    width: 8rem;
    height: 2px;

    margin: 0.8rem 0;
}
</style>
