const API_ERROR_LOCALIZATION: Map<string, string> = new Map([
    ['ATTEMPT_EXPIRED', 'Vypršel čas pro odevzdání pokusu.'],
    ['ATTEMPT_NOT_PENDING', 'Pokus již nelze odevzdat, vygeneruj nový.'],
    ['EMAIL_INVALID', 'Neplatná emailová adresa.'],
    ['EMAIL_OCCUPIED', 'Uživatel s tímto emailem už existuje.'],
    ['REGISTRATION_DISABLED', 'Registrace aktuálně není povolena.'],
    ['NOT_IN_COURSE', 'Uživatel není registrován do této soutěže.'],
    ['BAD_REQUEST', 'Neplatný požadavek.'],
    ['STUDENT_SCHOOL_MISSING', 'Je nutné vyplnit školu.'],
    ['INVALID_CODE', 'Neplatný ověřovací kód.'],
    ['SOURCE_REQUIRED', 'Chybí zdrojový kód.'],
    ['INVALID_CREDENTIALS', 'Špatné jméno nebo heslo.'],
    ['NOT_ORG_FINAL', 'Přidat uživatele do finále může jen organizátor.'],
    ['EMAIL_NOT_VERIFIED', 'Je nutné ověřit email.'],
    ['ATTEMPT_NOT_FOUND', 'Nenalezen pokus s daným ID.'],
    ['ATTEMPT_MAXIMUM_LIMIT', 'Počet pokusů přesáhl maximum.'],
    ['ATTEMPT_RECENT_LIMIT', 'Příliš mnoho nedávných pokusů, zkus to prosím později.'],
    ['COURSE_NOT_FOUND', 'Nenalezena soutež s daným ID.'],
    ['TASK_NOT_FOUND', 'Nenalezena úloha s daným ID.'],
    ['USER_NOT_FOUND', 'Nenalezen uživatel s daným ID.'],
    ['USER_ALREADY_REGISTERED_FOR_COURSE', 'Uživatel je již do soutěže registrován.'],
    ['ERROR_CREATING_CONTESTANT', 'Nepodařilo se přidat uživatele do soutěže.'],
    ['TASK_HAS_NO_SOLUTION', 'Nenalezeno řešení úlohy s daným ID.'],
    ['TASK_HAS_NO_AVAILABLE_SOLUTION', 'Řešení úlohy s daným ID ještě není dostupné.'],
    ['EXPECTED_ROLE_OTHER_FOR_FINISHED_COURSE', 'Do ukončené soutěže se nelze přihlásit jako účastník.'],
    ['ERROR_CREATING_ATTEMPT', 'Nepodařilo se vytvořit nový pokus na úlohu.'],
    ['INTERNAL_ERROR', 'Nespecifikovaná chyba serveru.'],
    ['INTERNAL_ERROR_UNCAUGHT', 'Neočekávaná chyba serveru.'],
    ['TASK_COURSE_NOT_FOUND', 'Neočekávaná chyba serveru.'],
]);


export function getApiErrorLocalization(
    err: string,
    defaultMessage: string | undefined = undefined,
): string {
    const result = API_ERROR_LOCALIZATION.get(err);
    if (result !== undefined) return result;
    if (defaultMessage !== undefined) return defaultMessage;
    return err;
}
