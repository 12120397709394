import jwtDecode from 'jwt-decode';
import * as Sentry from '@sentry/browser';
import { store } from '@/store';

class JwtService {
    private accessToken : string | null = null;

    getToken(): string | null {
        return this.accessToken;
    }

    saveToken(token: string) : void {
        this.accessToken = token;
        // set userId for Sentry
        // eslint-disable-next-line camelcase
        const { dat } = jwtDecode<{dat: { user_id: string, user_role: string, user_claim: string }}>(token);
        Sentry.setUser({ id: dat.user_id });

        if (dat.user_role === 'org') {
            store.commit('loginOrg');
        } else {
            store.commit('login');
        }
    }

    deleteToken() : void {
        this.accessToken = null;
        store.commit('logout');
    }

    isLoggedInNow() : boolean {
        return this.getToken() !== null;
    }
}

export default new JwtService();
