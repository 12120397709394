<template>
    <div class="bottom-banner text-center">
        <div v-if="$route.path === '/uvod/'">
            <Register id="register" register-button-text="Tak neotálej a registruj se!"/>
            <h3>Kdo a jak</h3>
            <h2><strong>může soutěžit?</strong></h2>

            <div class="container">
                <div class="line white"/>
            </div>
            <br>
            <p>
                Soutěž má dvě kola, domácí a finále v Praze. Určena je především pro studenty středních škol.
                Soutěží se v praktickém programování a každý může použít jakýkoliv jazyk. K úloze vždy obdržíš
                vstup a tvým úkolem bude odevzdat nám jen odpovídající výstup – je tedy jedno, jak ho spočítáš.
                Úlohy budou různě obtížné, jak ty nejjednodušší, tak i celkem náročné, takže si každý přijde na své.
            </p>
        </div>
        <div v-else>
            <h2><strong>Máš na to!</strong></h2>
            <div class="container">
                <div class="line white"/>
            </div>
            <h3>I ty můžeš vyhrát! Nebo to aspoň zkusit :)</h3>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Register from '@/components/auth/Register.vue';

export default defineComponent({
    name: 'BottomBanner',
    components: { Register },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* deep selector forces using scoped styles in child components */
#register :deep(button) {
  background-color: #331c47;
  border: 1px solid #ffffff;
  padding: 15px;
  color: white;
  position: relative;
  top: -66px;
}

#register :deep(button:hover) {
  background-color: #eeeeee;
  color: #331c47;
}

.bottom-banner {
  padding-bottom: 70px;
  padding-top: 40px;
  margin-top: 10px;
  background: #ff0052 url("@/assets/clouds.svg") no-repeat bottom -1px center;
  background-size: max(100%, 1920px);
  min-height: 300px;
  width: 100%;
}

.bottom-banner h2 {
  color: #ffffff;
}

.bottom-banner h3 {
  color: #ffffff;
}

.bottom-banner p {
  color: #ffffff;
  text-align: center;
  max-width: 750px;
  margin: 0 auto;
  font-size: 16px;
}

.line.white {
  background-color: #ffffff;
  width: 118px;
  height: 2px;
  position: relative;
  margin: 10px auto;
}

@media (min-width: 1900px) {
  .bottom-banner {
    background-size: 100%;
  }
}
</style>
