import katex from 'katex';

/**
 * Runs the KaTeX render on every <span class="math"> which is a child of the @param element.
 */
export function runKatex(element: HTMLElement): void {
    if (!element) return;
    const mathElements = element.getElementsByClassName('math');
    for (let i = 0; i < mathElements.length; i += 1) {
        const texText = mathElements[i].firstChild?.textContent ?? '';
        if (mathElements[i].tagName === 'SPAN') {
            katex.render(texText, mathElements[i] as HTMLElement, {
                displayMode: mathElements[i].classList.contains('display'),
                throwOnError: false,
            });
        }
    }
}

/** All the math inside `<span class="math inline">` and `<span class="math display">`
will be rendered using KaTeX whenever the component gets updated. */
export const katexDirective = {
    updated: runKatex,
    mounted: runKatex,
};

/*
    How to use KaTeX:
Add the `v-katex` directive. That's it. All the math inside <span class="math inline"> and <span class="math display">
will be rendered using KaTeX whenever the component gets updated. You can also use the runKatex directly on an element.
*/
