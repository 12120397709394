<template>
    <div>
        <b-button v-if="!$store.state.isLoggedIn" class="registration_btn" variant="primary"
            @click="showRegisterUserModal = true">
            {{ registerButtonText }}
        </b-button>

        <b-button v-if="$store.state.isLoggedIn && $store.state.shouldRegisterIntoCourse" class="registration_btn"
            variant="primary" @click="showRegisterCourseModal = true">
            Registrace do ročníku
        </b-button>

        <b-modal v-model="showRegisterUserModal" size="lg" title="Registrace" hide-footer>
            <b-alert v-if="userRegistrationComplete" variant="success" :model-value="true">
                <p>Registrace proběhla úspěšně. Na email jsme Ti poslali ověřovací kód.</p>
                <p class="no-bottom-margin">
                    Po ověření emailu se budeš moci přihlásit a řešit úlohy.
                </p>
            </b-alert>
            <b-form v-else :validated="validated" @submit.prevent="registerUser()">
                <p class="no-bottom-margin">
                    Pole označená
                    <RequiredStar :required="true" /> jsou povinná.
                </p>
                <section>
                    <div class="split-line">
                        <Field v-model="name" name="name" label="Jméno" type="text" required />
                        <Field v-model="surname" name="surname" label="Příjmení" type="text" required />
                    </div>
                    <Field v-model="email" name="email" label="Email" type="email" required :custom-validity="emailError" />
                    <div class="split-line">
                        <Field v-model="password" name="password" label="Heslo" type="password" required
                            :custom-validity="passwordError" />
                        <Field v-model="passwordAgain" name="password-again" label="Heslo znovu" type="password" required
                            :custom-validity="passwordAgainError" />
                    </div>
                </section>

                <section>
                    <h3>Několik zvědavých dotazů ...</h3>
                    <Field v-model="knowsFrom" label="Odkud nás znáš?" type="text" />
                    <Field v-model="otherGigs" label="Jaké jiné soutěže řešíš?" type="text" />
                </section>

                <section>
                    <h3>Zpracování osobních údajů</h3>
                    <CheckboxField v-model="gdpr" required :custom-validity="gdprError">
                        Souhlasím s podmínkami zpracování osobních údajů.
                    </CheckboxField>
                    <PersonalDataTerms />
                    <CheckboxField v-model="newsletter">
                        Chci na email dostávat informace o další super akcích od Matfyzu.
                    </CheckboxField>
                </section>

                <div class="register-btn-wrapper">
                    <b-button variant="primary" class="register-button" type="submit" @click="setValidated()">
                        Zaregistrovat se
                    </b-button>
                </div>
            </b-form>
        </b-modal>

        <b-modal v-model="showRegisterCourseModal" size="lg" title="Registrace do ročníku" hide-footer>
            <p class="no-bottom-margin">
                Pole označená
                <RequiredStar :required="true" /> jsou povinná.
            </p>
            <b-form :validated="validated" @submit.prevent="registerCourse()">
                <section>
                    <h3>Kdo jsi?</h3>
                    <b-form-group class="group">
                        <b-form-radio v-model="role" value="student">
                            Jsem student střední školy (nebo základní školy)
                        </b-form-radio>
                        <b-form-radio v-model="role" value="teacher">
                            Jsem učitel (soutěže se nezúčastním)
                        </b-form-radio>
                        <b-form-radio v-model="role" value="other">
                            Jsem někdo úplně jiný (soutěže se nezúčastním)
                        </b-form-radio>
                    </b-form-group>
                    <b-collapse :visible="schoolRequired">
                        <section>
                            <h3 class="no-bottom-margin">
                                Škola
                            </h3>
                            <div class="split-line">
                                <Field v-model="schoolName" label="Název" type="text" :required="schoolRequired"
                                    :custom-validity="schoolNameError" />
                                <Field v-model="schoolAddress" label="Adresa" type="text" :required="schoolRequired" />
                            </div>
                            <Field v-model="schoolTown" label="Město" type="text" :required="schoolRequired" />

                            <b-collapse :visible="role === 'student'">
                                <div class="split-line">
                                    <Field v-model="graduationYear" label="Rok maturity" type="number"
                                        :required="role === 'student'" />
                                    <Field v-model="teacherName" label="Jméno tvého učitele" type="text"
                                        placeholder="matematiky nebo informatiky" :required="role === 'student'" />
                                </div>
                            </b-collapse>
                        </section>
                    </b-collapse>
                </section>

                <div class="register-btn-wrapper">
                    <b-button variant="primary" class="register-button" type="submit" @click="setValidated()">
                        Zaregistrovat se do ročníku
                    </b-button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script lang="ts">
import { AxiosError } from 'axios';
import authService from '@/api/AuthService';
import Field from '@/components/forms/Field.vue';
import CheckboxField from '@/components/forms/CheckboxField.vue';
import courseService, { Role } from '@/api/CourseService';
import PersonalDataTerms from '@/components/auth/PersonalDataTerms.vue';
import RequiredStar from '@/components/forms/RequiredStar.vue';
import { store } from '@/store';
import { defineComponent, reactive, watch } from 'vue';

export default defineComponent({
    components: {
        Field,
        CheckboxField,
        PersonalDataTerms,
        RequiredStar,
    },
    props: {
        registerButtonText: {
            type: String,
            default: 'Registrace',
        },
    },
    data() {
        return {
            showRegisterUserModal: false,
            showRegisterCourseModal: false,

            validated: false,
            userRegistrationComplete: false,

            // User registration
            email: '',
            password: '',
            name: '',
            surname: '',
            passwordAgain: '',
            knowsFrom: '',
            otherGigs: '',
            gdpr: false,
            newsletter: false,

            // Course registration
            role: Role.STUDENT,

            // Teachers or Student
            schoolName: '',
            schoolAddress: '',
            schoolTown: '',

            // Students only
            graduationYear: String(new Date().getFullYear()),
            teacherName: '',

            // One time errors
            emailError: '',
            schoolNameError: '',
        };
    },
    created(): void {
        this.updateShouldRegisterIntoCourse();

        this.$store.subscribe((mutation) => {
            if (mutation.type === 'login' || mutation.type === 'loginOrg') {
                this.updateShouldRegisterIntoCourse();
            }
            if (mutation.type === 'logout') {
                this.userRegistrationComplete = false;
                this.clearFields();
            }
        });

        watch(() => this.email, () => {
            this.emailError = '';
        });

        watch(() => this.schoolName, () => {
            this.schoolNameError = '';
        });

        watch([() => this.showRegisterUserModal, () => this.showRegisterCourseModal], () => {
            this.validated = false;
        });
    },
    methods: {
        async updateShouldRegisterIntoCourse(): Promise<void> {
            const currentCourse = await courseService.getLastActiveCourse();

            if (currentCourse === null) {
                store.commit('unsetShouldRegisterIntoCourse');
            } else if (currentCourse.contestant_id === null) {
                store.commit('setShouldRegisterIntoCourse');
            } else {
                store.commit('unsetShouldRegisterIntoCourse');
            }
        },
        setValidated(): void {
            this.validated = true;
        },
        clearFields(): void {
            this.email = '';
            this.password = '';
            this.name = '';
            this.surname = '';
            this.passwordAgain = '';
            this.knowsFrom = '';
            this.otherGigs = '';
            this.role = Role.STUDENT;

            // Teachers or Student
            this.schoolName = '';
            this.schoolAddress = '';
            this.schoolTown = '';

            // Students only
            this.graduationYear = String(new Date().getFullYear());
            this.teacherName = '';
            this.gdpr = false;
            this.newsletter = false;
        },
        async registerUser(): Promise<void> {
            try {
                if (!this.$store.state.isLoggedIn) {
                    const registerResult = await authService.register(
                        {
                            email: this.email,
                            password: this.password,
                            firstName: this.name,
                            lastName: this.surname,
                            otherGigs: this.otherGigs,
                            mffSpam: this.newsletter,
                            knowsFrom: this.knowsFrom,
                        },
                        this.handleErrorAuthRegister,
                    );

                    if (registerResult === null) {
                        // error in the request
                        this.$forceUpdate(); // redraw the validation messages
                        return;
                    }
                }
            } catch (error) {
                console.error(error);
            }
            this.userRegistrationComplete = true;
        },
        async registerCourse(): Promise<void> {
            try {
                if (this.$store.state.shouldRegisterIntoCourse) {
                    const currentCourse = await courseService.getLastActiveCourse();
                    if (currentCourse !== null) {
                        await courseService.registerIntoCourse(
                            currentCourse.id,
                            {
                                contestantRole: this.role,
                                maturita: Number(this.graduationYear),
                                teacher: this.teacherName,
                                school: {
                                    address: this.schoolAddress,
                                    name: this.schoolName,
                                    city: this.schoolTown,
                                },
                            },
                            this.handleErrorCourseRegister,
                        );
                    }
                    store.commit('unsetShouldRegisterIntoCourse');
                }
            } catch (error) {
                console.error(error);
            }
            this.showRegisterCourseModal = false;
        },
        handleErrorAuthRegister(error: AxiosError): boolean {
            if (error?.response?.data === 'EMAIL_INVALID') {
                this.emailError = 'Neplatný email.';
                return true;
            }
            if (error?.response?.data === 'EMAIL_OCCUPIED') {
                this.emailError = 'Uživatel s tímto emailem už existuje.';
                return true;
            }
            return false;
        },
        handleErrorCourseRegister(error: AxiosError): boolean {
            if (error?.response?.data === 'STUDENT_SCHOOL_MISSING') {
                this.schoolNameError = 'Pro účast v soutěži musíte vyplnit školu.';
                return true;
            }
            return false;
        }
    },
    computed: {
        schoolRequired() {
            return ['student', 'teacher'].includes(this.role)
        },
        passwordError() {
            if (this.password.length < 6) {
                return 'Heslo je moc krátké.';
            }

            return '';
        },
        passwordAgainError() {
            if (this.password !== this.passwordAgain) {
                return 'Hesla se neshodují.';
            }

            return '';
        },
        gdprError() {
            if (!this.gdpr) {
                return 'Pro účast v soutěži musíte souhlasit s našimi podmínkami.';
            }

            return '';
        },
    }
});
</script>

<style scoped>
form {
    padding: 8px;
}

h3 {
    color: #ff0052;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 8px;
}

.no-bottom-margin {
    margin-bottom: 0;
}

.split-line {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.split-line>* {
    flex-grow: 1;
}

.register-btn-wrapper {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
}

.group {
    margin: 8px;
}
</style>
