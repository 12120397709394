<template>
    <div id="app">
        <SnackbarProvider/>
        <MainMenu :menu="menu"/>

        <LandingHeader v-if="meta.headerStyle == 'landing'"/>
        <SmallHeader v-else :top="meta.headlineTop" :bottom="meta.headlineBottom"/>

        <section v-if="meta.noSubMenu" class="main_container">
            <router-view/>
        </section>
        <section v-else class="container main_container">
            <div class="row flex-grow-1">
                <nav v-if="submenu.length > 0" id="submenu" class="col-12 col-md-3">
                    <SubMenuList :submenu="submenu" :prepath="testPath"/>
                </nav>
                <div v-else class="empty_space d-none d-md-block col-12 col-md-3">
                    <img src="@/assets/raketa_bez_loga.svg">
                </div>

                <router-view class="col-12 col-md-9"/>
            </div>
        </section>

        <BottomBanner/>
        <Footer/>
    </div>
</template>

<script setup lang="ts">
import MainMenu from '@/components/menu/MainMenu.vue';
import SubMenuList from '@/components/menu/SubMenuList.vue';
import { GeneratedMenu, GeneratedItem, RouteMeta } from '@/types';
import LandingHeader from '@/components/common/LandingHeader.vue';
import SmallHeader from '@/components/common/SmallHeader.vue';
import BottomBanner from '@/components/common/BottomBanner.vue';
import Footer from '@/components/common/Footer.vue';
import SnackbarProvider from '@/components/ui/SnackbarProvider.vue';
import authService from '@/api/AuthService';
import { allowAccess } from './menu';
import { useRoute } from 'vue-router';
import { computed, onMounted, ref, watchEffect } from 'vue';

const props = defineProps<{
    menuStromecek: GeneratedMenu[],
}>();

const route = useRoute();
const meta = computed(() => route?.meta as RouteMeta);
const submenu = ref<GeneratedItem[]>([]);
const testPath = ref('');

watchEffect(() => {
    const result: GeneratedMenu | undefined = props.menuStromecek.find(
        (e: GeneratedMenu) => route.path.startsWith(e.path),
    );
    submenu.value = result ? result.subpages : [];
    testPath.value = result ? result.path : '';
});

function removeInaccessible(menu: GeneratedItem[]): GeneratedItem[] {
    return menu.filter(allowAccess);
}

const menu = computed(() => removeInaccessible(props.menuStromecek));

onMounted(() => {
    authService.refreshLogin();
});
</script>

<style lang="scss">
body {
    overflow-y: scroll;
}
</style>

<style scoped>
.container {
    display: flex;
    align-items: flex-start;
}

#submenu {
    margin-bottom: 15px;
}

.main_container {
    padding-top: 10px;
}

.empty_space img {
    width: 100%;
    margin-top: 30px;
}
</style>
