<template>
    <div class="snackbar-container">
        <Snack
            v-for="snack in snacks"
            :key="snack.uuid"
            :snack="snack"
            @dismissed="onSnackDismissed(snack)"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

// Import the components used in the template
import Snack from './Snack.vue';
import snackbarService, { SnackObject } from '@/api/SnackbarService';

export default defineComponent({
    components: {
        Snack,
    },
    setup() {

        // Define reactive data
        const snacks = ref<SnackObject[]>([]);

        // Register the listener when the component is created
        const onSnack = (snack: SnackObject) => {
            snacks.value.push(snack);
        };
        snackbarService?.addListener(onSnack);

        // Unregister the listener when the component is destroyed
        const onSnackDismissed = (snack: SnackObject): void => {
            snacks.value = snacks.value.filter((s: SnackObject) => s.uuid !== snack.uuid);
        };
        snackbarService?.removeListener(onSnackDismissed);

        return {
            snacks,
            onSnackDismissed,
        };
    },
});
</script>

<style scoped>
.snackbar-container {
    z-index: 1060; /* bootstrap popover z-index */
    position: fixed;
    right: 10px;
    top: 10px;
}
</style>
