<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-8">
                <p>Požadovaná stránka nebyla nalezena.</p>
                <p>The requested URL was not found on this server.</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NotFoundpage',
});
</script>

<style scoped>
p {
    text-align: center;
}
</style>
