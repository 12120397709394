<template>
    <header>
        <div v-if="$props.stars" class="stars">
            <Starfield/>
        </div>

        <div class="main">
            <slot/>
        </div>
    </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Starfield from '@/components/ui/Starfield.vue';

export default defineComponent({
    name: 'Header',
    props: {
        stars: Boolean,
    },
});
</script>

<style scoped>
header {
    position: relative;
    background-color: #331c47;
    isolation: isolate;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 1.5rem;
    padding-bottom: 60px;

    background: url("@/assets/clouds.svg") center bottom no-repeat;
    background-size: max(100%, 1920px);
}

.stars {
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: -1;
}
</style>
