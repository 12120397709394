<template>
    <div v-if="!showTerms" class="terms">
        <b-link @click="showTerms = true">
            Zobrazit text
        </b-link>
    </div>
    <div v-else class="terms">
        <b-link @click="showTerms = false">
            Skrýt text
        </b-link>
        <section>
            <h4>Zpracování osobních údajů</h4>
            <p>
                Kasiopea je propagační akcí Matematicko-fyzikální fakulty Univerzity
                Karlovy (dále jen Matfyz). Pro její provoz potřebujeme zpracovávat
                některé Tvé osobní údaje. Zde se dozvíš, jaké to jsou a co s nimi děláme.
            </p>
            <p>
                Získáváme od Tebe údaje vyplněné v přihlášce do soutěže (jméno, příjmení,
                e-mailovou adresu, informace o Tvé škole). Také uchováváme všechna řešení,
                která nám pošleš, a jejich hodnocení.
            </p>
            <p>
                Slibujeme Ti, že Tvá osobní data nezneužijeme k ničemu, co by nesouviselo
                s Kasiopeou nebo s dalšími aktivitami Matfyzu, a nikdy je nepředáme nikomu
                cizímu. Údaje využíváme k zajištění chodu soutěže a také je sdílíme s
                ostatními propagačními akcemi Matfyzu, abychom mohli vyhodnocovat úspěšnost
                akcí. Pokud budeš mít zájem, budeme Ti také posílat zajímavé zprávy a novinky
                týkajíci se Kasiopey, informatiky a Matfyzu.
            </p>
            <p>
                Veřejně vystavujeme pouze výsledkové listiny, které také uchováváme pro
                archivní účely. Pokud ale z nějakého důvodu nebudeš chtít mít své jméno
                uvedené ve výsledkové listině, není problém to zařídit, napiš nám.
            </p>
            <p>
                Na akcích semináře navíc pořizujeme fotografie a videozáznamy a používáme
                je ke zpravodajským a propagačním účelům. Pro propagační účely si od Tebe
                vyžádáme samostatný souhlas na začátku akce.
            </p>

            <h4>Souhlas se zpracováním osobních údajů pro potřeby chodu semináře</h4>
            <p>
                Tímto uděluji souhlas Univerzitě Karlově, se sídlem Ovocný trh 560/5,
                116 36 Praha 1, IČO 00216208 (dále jen UK), která je správcem osobních
                údajů všech fakult a součástí UK, ke zpracování osobních údajů pro potřeby
                soutěže Kasiopea Matematicko-fyzikální fakulty UK (dále jen Kasiopea a MFF UK).
            </p>
            <p>
                Tento souhlas uděluji pro všechny výše uvedené osobní údaje, a to po dobu
                účasti v semináři a 10 let poté, a dále souhlasím s uchováváním potřebných
                dat pro archivní účely i po této lhůtě (vystavené výsledkové listiny aj.).
            </p>
            <p>
                MFF UK tyto údaje zpracovává za účelem evidence řešitelů a účastníků Kasiopey,
                k zajištění celoročního fungování soutěže, analýze účinnosti jednotlivých
                propagačních akcí MFF UK a zpravodajským účelům. Osobám, které o to projeví zájem
                v nastavení svého účtu, bude MFF UK také zasílat propagační materiály.
            </p>
            <p>Údaje nebudou předány třetí osobě ani využívány k jiným účelům, než ke kterým byly poskytnuty.</p>
            <p>
                Tento souhlas uděluji ze své vlastní a svobodné vůle a beru na vědomí, že jej mohu kdykoliv
                odvolat zasláním e-mailu na adresu <a href="mailto:kasiopea@matfyz.cz">kasiopea@matfyz.cz</a>.
                Stejně tak může být požadováno vymazání i z archivních údajů Kasiopey, pokud to bude technicky možné.
            </p>
            <p>Dále máte právo:</p>
            <ul>
                <li>požádat o informaci, jaké osobní údaje jsou o vás zpracovávány,</li>
                <li>požadovat opravu osobních údajů, pokud jsou neplatné nebo zastaralé,</li>
                <li>
                    požadovat, aby nebyly vaše osobní údaje zpracovávány do doby, než
                    bude vyřešena oprávněnost výše uvedených požadavků,
                </li>
                <li>požadovat, aby byly vaše osobní údaje předány jinému správci,</li>
                <li>podat stížnost u dozorového úřadu.</li>
            </ul>
            <p>
                V případě jakéhokoliv dotazu nebo uplatnění svých práv můžete kontaktovat pověřence pro
                ochranu osobních údajů na e-mailové adrese <a href="mailto:gdpr@cuni.cz">gdpr@cuni.cz</a>.
            </p>
        </section>
        <b-link @click="showTerms = false">
            Skrýt text
        </b-link>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PersonalDataTerms',
    data() {
        return { showTerms: false };
    },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.terms {
    margin-left: calc(8px + 1.5rem);
}
h4 {
    color: #ff0052;
    font-weight: 700;
}
a {
    font-weight: bold;
}
</style>
