import { VuePictureSwipeItem } from '@/types';

/**
 * This function can be used to dynamically import images from the `assets` folder.
 * WARNING: The path must be relative to the `assets` folder.
 * See https://vitejs.dev/guide/assets.html#new-url-url-import-meta-url for details
 */
export function assetsImageURL(path: string) : string {
    return new URL(`../assets/${path}`, import.meta.url).href;
}

/* Thumbnails (produced with ZPS): max-width: 500; JPEG quality: 80 */
const itemsOSoutezi: Record<number, VuePictureSwipeItem[]> = {
    2015: [{
        id: '1',
        src: assetsImageURL('gallery/1516/1516_1.jpg'),
        thumbnail: assetsImageURL('gallery/1516/1516_1_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }, {
        id: '2',
        src: assetsImageURL('gallery/1516/1516_2.jpg'),
        thumbnail: assetsImageURL('gallery/1516/1516_2_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }, {
        id: '3',
        src: assetsImageURL('gallery/1516/1516_3.jpg'),
        thumbnail: assetsImageURL('gallery/1516/1516_3_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }, {
        id: '4',
        src: assetsImageURL('gallery/1516/1516_4.jpg'),
        thumbnail: assetsImageURL('gallery/1516/1516_4_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }],
    2016: [{
        id: '1',
        src: assetsImageURL('gallery/1617/1617_1.jpg'),
        thumbnail: assetsImageURL('gallery/1617/1617_1_thumbnail.jpg'),
        w: 1003,
        h: 564,
    }, {
        id: '2',
        src: assetsImageURL('gallery/1617/1617_2.jpg'),
        thumbnail: assetsImageURL('gallery/1617/1617_2_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }, {
        id: '3',
        src: assetsImageURL('gallery/1617/1617_3.jpg'),
        thumbnail: assetsImageURL('gallery/1617/1617_3_thumbnail.jpg'),
        w: 2000,
        h: 851,
    }, {
        id: '4',
        src: assetsImageURL('gallery/1617/1617_4.jpg'),
        thumbnail: assetsImageURL('gallery/1617/1617_4_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }, {
        id: '5',
        src: assetsImageURL('gallery/1617/1617_5.jpg'),
        thumbnail: assetsImageURL('gallery/1617/1617_5_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }],
    2017: [{
        id: '1',
        src: assetsImageURL('gallery/1718/1718_1.jpg'),
        thumbnail: assetsImageURL('gallery/1718/1718_1_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }, {
        id: '2',
        src: assetsImageURL('gallery/1718/1718_2.jpg'),
        thumbnail: assetsImageURL('gallery/1718/1718_2_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }, {
        id: '3',
        src: assetsImageURL('gallery/1718/1718_3.jpg'),
        thumbnail: assetsImageURL('gallery/1718/1718_3_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }, {
        id: '4',
        src: assetsImageURL('gallery/1718/1718_4.jpg'),
        thumbnail: assetsImageURL('gallery/1718/1718_4_thumbnail.jpg'),
        w: 1003,
        h: 564,
    }, {
        id: '5',
        src: assetsImageURL('gallery/1718/1718_5.jpg'),
        thumbnail: assetsImageURL('gallery/1718/1718_5_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }],
    2018: [{
        id: '1',
        src: assetsImageURL('gallery/1819/1819_1.jpg'),
        thumbnail: assetsImageURL('gallery/1819/1819_1_thumbnail.jpg'),
        w: 2048,
        h: 1152,
    }, {
        id: '2',
        src: assetsImageURL('gallery/1819/1819_2.jpg'),
        thumbnail: assetsImageURL('gallery/1819/1819_2_thumbnail.jpg'),
        w: 2048,
        h: 1152,
    }, {
        id: '3',
        src: assetsImageURL('gallery/1819/1819_3.jpg'),
        thumbnail: assetsImageURL('gallery/1819/1819_3_thumbnail.jpg'),
        w: 2048,
        h: 1152,
    }, {
        id: '4',
        src: assetsImageURL('gallery/1819/1819_4.jpg'),
        thumbnail: assetsImageURL('gallery/1819/1819_4_thumbnail.jpg'),
        w: 2048,
        h: 1152,
    }, {
        id: '5',
        src: assetsImageURL('gallery/1819/1819_5.jpg'),
        thumbnail: assetsImageURL('gallery/1819/1819_5_thumbnail.jpg'),
        w: 2048,
        h: 1152,
    }, {
        id: '6',
        src: assetsImageURL('gallery/1819/1819_6.jpg'),
        thumbnail: assetsImageURL('gallery/1819/1819_6_thumbnail.jpg'),
        w: 2048,
        h: 889,
    }],
    2019: [{
        id: '1',
        src: assetsImageURL('gallery/1920/1920_1.jpg'),
        thumbnail: assetsImageURL('gallery/1920/1920_1_thumbnail.jpg'),
        w: 1003,
        h: 564,
    }, {
        id: '2',
        src: assetsImageURL('gallery/1920/1920_2.jpg'),
        thumbnail: assetsImageURL('gallery/1920/1920_2_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }, {
        id: '3',
        src: assetsImageURL('gallery/1920/1920_3.jpg'),
        thumbnail: assetsImageURL('gallery/1920/1920_3_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }, {
        id: '4',
        src: assetsImageURL('gallery/1920/1920_4.jpg'),
        thumbnail: assetsImageURL('gallery/1920/1920_4_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }, {
        id: '5',
        src: assetsImageURL('gallery/1920/1920_5.jpg'),
        thumbnail: assetsImageURL('gallery/1920/1920_5_thumbnail.jpg'),
        w: 2000,
        h: 1125,
    }, {
        id: '6',
        src: assetsImageURL('gallery/1920/1920_6.jpg'),
        thumbnail: assetsImageURL('gallery/1920/1920_6_thumbnail.jpg'),
        w: 2000,
        h: 802,
    }],
    2020: [{
        id: '1',
        src: assetsImageURL('gallery/2021/2021_1.jpg'),
        thumbnail: assetsImageURL('gallery/2021/2021_1_thumbnail.jpg'),
        w: 4576,
        h: 2585,
    }, {
        id: '2',
        src: assetsImageURL('gallery/2021/2021_2.jpg'),
        thumbnail: assetsImageURL('gallery/2021/2021_2_thumbnail.jpg'),
        w: 4413,
        h: 2495,
    }, {
        id: '3',
        src: assetsImageURL('gallery/2021/2021_3.jpg'),
        thumbnail: assetsImageURL('gallery/2021/2021_3_thumbnail.jpg'),
        w: 4536,
        h: 2569,
    }, {
        id: '4',
        src: assetsImageURL('gallery/2021/2021_4.jpg'),
        thumbnail: assetsImageURL('gallery/2021/2021_4_thumbnail.jpg'),
        w: 4400,
        h: 2491,
    }, {
        id: '5',
        src: assetsImageURL('gallery/2021/2021_5.jpg'),
        thumbnail: assetsImageURL('gallery/2021/2021_5_thumbnail.jpg'),
        w: 4378,
        h: 2469,
    }, {
        id: '6',
        src: assetsImageURL('gallery/2021/2021_6.jpg'),
        thumbnail: assetsImageURL('gallery/2021/2021_6_thumbnail.jpg'),
        w: 1773,
        h: 999,
    }],
    2021: [{
        id: '1',
        src: assetsImageURL('gallery/2122/2122_1.jpg'),
        thumbnail: assetsImageURL('gallery/2122/2122_1_thumbnail.jpg'),
        w: 6000,
        h: 4000,
    }, {
        id: '2',
        src: assetsImageURL('gallery/2122/2122_2.jpg'),
        thumbnail: assetsImageURL('gallery/2122/2122_2_thumbnail.jpg'),
        w: 6000,
        h: 4000,
    }, {
        id: '3',
        src: assetsImageURL('gallery/2122/2122_3.jpg'),
        thumbnail: assetsImageURL('gallery/2122/2122_3_thumbnail.jpg'),
        w: 6000,
        h: 4000,
    }, {
        id: '4',
        src: assetsImageURL('gallery/2122/2122_4.jpg'),
        thumbnail: assetsImageURL('gallery/2122/2122_4_thumbnail.jpg'),
        w: 6000,
        h: 4000,
    }, {
        id: '5',
        src: assetsImageURL('gallery/2122/2122_5.jpg'),
        thumbnail: assetsImageURL('gallery/2122/2122_5_thumbnail.jpg'),
        w: 6000,
        h: 4000,
    }, {
        id: '6',
        src: assetsImageURL('gallery/2122/2122_6.jpg'),
        thumbnail: assetsImageURL('gallery/2122/2122_6_thumbnail.jpg'),
        w: 6000,
        h: 4000,
    }, {
        id: '7',
        src: assetsImageURL('gallery/2122/2122_7.png'),
        thumbnail: assetsImageURL('gallery/2122/2122_7_thumbnail.jpg'),
        w: 1670,
        h: 993,
    }],
    2022: [{
        id: '1',
        src: assetsImageURL('gallery/2223/2223_1.jpg'),
        thumbnail: assetsImageURL('gallery/2223/2223_1_thumbnail.jpg'),
        w: 6000,
        h: 4000,
    }, {
        id: '2',
        src: assetsImageURL('gallery/2223/2223_2.jpg'),
        thumbnail: assetsImageURL('gallery/2223/2223_2_thumbnail.jpg'),
        w: 6000,
        h: 4000,
    }, {
        id: '3',
        src: assetsImageURL('gallery/2223/2223_3.jpg'),
        thumbnail: assetsImageURL('gallery/2223/2223_3_thumbnail.jpg'),
        w: 4229,
        h: 2820,
    }, {
        id: '4',
        src: assetsImageURL('gallery/2223/2223_4.jpg'),
        thumbnail: assetsImageURL('gallery/2223/2223_4_thumbnail.jpg'),
        w: 6000,
        h: 4000,
    }, {
        id: '5',
        src: assetsImageURL('gallery/2223/2223_5.jpg'),
        thumbnail: assetsImageURL('gallery/2223/2223_5_thumbnail.jpg'),
        w: 5189,
        h: 1696,
    }],
};

export default itemsOSoutezi;
