<template>
    <svg viewBox="0 0 1 1" preserveAspectRatio="xMidYMid slice">
        <path :d="drawLayerPath(1, 50, 30e-4)" fill="#8356b1" style="--period: 7s"/>
        <path :d="drawLayerPath(2, 70, 25e-4)" fill="#8356b1" style="--period: 10s"/>
        <path :d="drawLayerPath(3, 90, 20e-4)" fill="#8356b1" style="--period: 13s"/>
        <path :d="drawLayerPath(4, 110, 15e-4)" fill="#8356b1" style="--period: 16s"/>
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { LCG } from '@/lcg';

export default defineComponent({
    name: 'Starfield',
    methods: {
        drawLayerPath,
    },
});

function drawCircle(x: number, y: number, radius: number): string {
    const move = `M ${x - radius} ${y}`;
    const first = `A ${radius} ${radius} 0 1 0 ${x + radius} ${y}`;
    const second = `A ${radius} ${radius} 0 1 0 ${x - radius} ${y}`;
    return `${move} ${first} ${second}`;
}

function drawLayerPath(seed: number, count: number, radius: number): string {
    const rng = new LCG(seed);
    const strokes = [];

    for (let i = 0; i < count; i++) {
        const x = rng.next();
        const y = rng.next();
        const r = radius * (1 - rng.next() / 5);

        strokes.push(drawCircle(x, y, r));
        strokes.push(drawCircle(x, y + 1, r));
    }

    return strokes.join(' ');
}
</script>

<style scoped>
svg {
    width: 100%;
    height: 100%;
}

path {
    animation: shift var(--period) linear infinite reverse;
}

@keyframes shift {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(-1px);
    }
}

@media (prefers-reduced-motion) {
    path {
        display: none;
    }
}
</style>
