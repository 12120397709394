import { createStore } from 'vuex';

// define your typings for the store state
export interface State {
  /** If user is currently logged in */
  isLoggedIn: boolean;
  /** If the user is not registered into the current course */
  shouldRegisterIntoCourse: boolean;
  /** If the current user is org */
  isOrg: boolean;
}

export const store = createStore<State>({
    state() {
        return {
            isLoggedIn: false,
            shouldRegisterIntoCourse: false,
            isOrg: false,
        };
    },
    mutations: {
        login(state) {
            state.isLoggedIn = true;
        },
        loginOrg(state) {
            state.isLoggedIn = true;
            state.isOrg = true;
        },
        logout(state) {
            state.isLoggedIn = false;
            state.shouldRegisterIntoCourse = true;
            state.isOrg = false;
        },
        setShouldRegisterIntoCourse(state) {
            state.shouldRegisterIntoCourse = true;
        },
        unsetShouldRegisterIntoCourse(state) {
            state.shouldRegisterIntoCourse = false;
        },
    },
});
